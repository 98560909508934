<script>

export default {
  components: {
    config1Empresa: () => import('./config1Empresa.vue'),
    Config2Software: () => import('./Config2Software.vue'),
    config3Certificado: () => import('./config3Certificado.vue')
  }
}
</script>

<template>
  <div class="container-fluid">
    <b-card>
      <h3>
        Conexión DIAN
      </h3>
      <!-- 1 configuración de la empresa-->
      <config1Empresa />
      <!-- 7 software nomina-->
      <Config2Software />
      <!-- 3 certificado-->
      <config3Certificado />
      <!-- 8 resolución nomina  o de ajuste, la resolución no es obligatoria para nomina.-->


    </b-card>  
  </div>
</template>

